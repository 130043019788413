.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
}

.cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-auto-flow: row;
    grid-template-areas:
    ". . ."
    ". . .";
    gap: 20px;
    position: relative;
}

.card {
    transition: all ease-in-out 200ms;
    max-width: 300px;
}

.card, .infoBlock {
    position: relative;
    overflow: hidden;
    padding: 20px;
    background: grey;
    border-radius: 20px;
    box-shadow: 0 6px 26px -9px rgba(0, 0, 0, 0.55);
    width: 100%;
    height: 280px;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px !important;
}

.infoBlock .defaultBlock b {
    color: #00d067;
}

.infoBlock .defaultBlock div:nth-child(1) {
    font-size: 50px !important;
}

.infoBlock .defaultBlock div:nth-child(2) a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
}

.infoBlock .defaultBlock div:nth-child(2) a img {
    padding: 0 23px 0 6px;
}

.info {
    position: absolute;
    bottom: -80px;
    color: white;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 50px;
    text-align: center;
}

.atomCard {
    width: 600px;
    height: 600px;
    opacity: 0.2;
    position: absolute;
    top: -69px;
    left: 45px;
    transform: rotate(47deg);
    background-position: left !important;
    background-color: transparent !important;
    transition: all ease-in-out 400ms;
}

.card:hover .atomCard {
    /*transform: scale(0.8) rotate(30deg);*/
    /*left: -60px;*/
    transform: scale(1.05) rotate(47deg);
}

.card:hover {
    transform: scale(1.02);
}

.card:active {
    transform: scale(1.06);
}

.card a {
    color: initial;
    text-decoration: none;
}

.cardTitle {
    font-size: 40px;
    font-weight: bold;
}

.infoBlock {
    cursor: initial;
    background: #000000cc;
}

@media only screen and (max-width: 540px) {
    .container {
        align-items: initial;
    }

    .cards {
        box-sizing: content-box;
        padding: 30px 0 !important;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:"."".";
    }

    .card {
        max-width: 320px;
    }
}