body {
  margin: 0;
}

div, textarea {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}